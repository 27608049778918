import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADDON, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemAddonMaybe = props => {
  const { lineItems, addons, intl } = props;

  if (!addons?.length > 0) {
    return null;
  }

  const addonLineItems = lineItems?.filter(item => item.code === LINE_ITEM_ADDON && !item.reversal);

  return addonLineItems ? (
    <>
      <hr className={css.totalDivider} />

      <div className={css.lineItemTotal}>
        <span className={css.itemLabel}>
          <FormattedMessage id="General.addos" />
        </span>
      </div>

      {addonLineItems.map((addon, i) => (
        <div key={`addon_${i}`} className={css.lineItem}>
          <span className={css.itemLabel}>{addons[i].title}</span>
          <span className={css.itemValue}>{formatMoney(intl, addon.lineTotal)}</span>
        </div>
      ))}
    </>
  ) : null;
};

LineItemAddonMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAddonMaybe;
