import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEyeOpen.module.css';

const IconEyeOpen = props => {
  const { className, rootClassName } = props;

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);

  return (
    <svg
      className={classes}
      width="28"
      height="28"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="6" />
      <path d="M45.3,22.1h0C43.2,19.5,35.4,11,24,11S4.8,19.5,2.7,22.1a3,3,0,0,0,0,3.8C4.8,28.5,12.6,37,24,37s19.2-8.5,21.3-11.1A3,3,0,0,0,45.3,22.1ZM24,33c-8.8,0-15.3-6.2-17.7-9,2.4-2.8,8.9-9,17.7-9s15.3,6.2,17.7,9C39.3,26.8,32.8,33,24,33Z" />
    </svg>
  );
};

IconEyeOpen.defaultProps = {
  className: null,
  rootClassName: null,
};

IconEyeOpen.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEyeOpen;
