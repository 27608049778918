import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_USER_REQUEST = 'app/ProfilePublicPoliciesPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePublicPoliciesPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePublicPoliciesPage/SHOW_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_USER_REQUEST:
      return { ...state, userId: payload.userId, userShowError: null };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));

  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  return Promise.all([dispatch(fetchCurrentUser()), dispatch(showUser(userId))]);
};
