import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconVolume.module.css';

const IconVolume = props => {
  const { className, rootClassName, isOn = true } = props;
  const classes = classNames(rootClassName || css.root, className, { [css.disabled]: !isOn });

  return isOn ? (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M20.807,4.29a1,1,0,0,0-1.415,1.415,8.913,8.913,0,0,1,0,12.59,1,1,0,0,0,1.415,1.415A10.916,10.916,0,0,0,20.807,4.29Z" />
      <path d="M18.1,7.291A1,1,0,0,0,16.68,8.706a4.662,4.662,0,0,1,0,6.588A1,1,0,0,0,18.1,16.709,6.666,6.666,0,0,0,18.1,7.291Z" />
      <path d="M13.82.2A12.054,12.054,0,0,0,6.266,5H5a5.008,5.008,0,0,0-5,5v4a5.008,5.008,0,0,0,5,5H6.266A12.059,12.059,0,0,0,13.82,23.8a.917.917,0,0,0,.181.017,1,1,0,0,0,1-1V1.186A1,1,0,0,0,13.82.2ZM13,21.535a10.083,10.083,0,0,1-5.371-4.08A1,1,0,0,0,6.792,17H5a3,3,0,0,1-3-3V10A3,3,0,0,1,5,7h1.8a1,1,0,0,0,.837-.453A10.079,10.079,0,0,1,13,2.465Z" />
    </svg>
  ) : (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="m15.004,20.004v2.812c0,.297-.132.579-.36.769-.181.151-.407.231-.64.231-.061,0-.121-.005-.181-.017-2.999-.551-5.752-2.299-7.554-4.794h-1.27C2.242,19.004-.002,16.761-.002,14.002v-4.001C-.002,8.885.359,7.828,1.043,6.944c.34-.437.966-.516,1.403-.179.437.338.517.966.179,1.403-.41.529-.627,1.163-.627,1.832v4.001c0,1.655,1.347,3.002,3.002,3.002h1.78c.321,0,.622.154.811.414l.3.415c1.263,1.749,3.083,3.057,5.113,3.703v-1.532c0-.552.447-1,1-1s1,.448,1,1Zm8.703,3.703c-.195.195-.451.293-.707.293s-.512-.098-.707-.293L.293,1.707C-.098,1.316-.098.684.293.293S1.316-.098,1.707.293l4.623,4.623C8.131,2.468,10.855.753,13.822.205c.293-.053.594.025.821.215.229.19.36.472.36.769v12.402l1.686,1.686c.846-.887,1.31-2.046,1.31-3.276,0-1.269-.494-2.461-1.392-3.359-.391-.39-.391-1.023,0-1.414s1.023-.391,1.414,0c1.275,1.275,1.978,2.97,1.978,4.773,0,1.764-.673,3.425-1.896,4.69l1.415,1.415c3.33-3.418,3.304-8.908-.081-12.292-.391-.391-.391-1.023,0-1.414.391-.391,1.023-.391,1.414,0,4.164,4.164,4.191,10.922.081,15.12l2.774,2.774c.391.391.391,1.023,0,1.414ZM7.762,6.348l5.242,5.242V2.468c-2.032.647-3.854,1.955-5.114,3.702l-.128.178Z" />
    </svg>
  );
};

IconVolume.defaultProps = { className: null };

IconVolume.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconVolume;
