import React, { useCallback, useEffect, useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, IconSpinner, Modal } from '../../../components';
import { useLocation } from 'react-router-dom';
import { parse } from '../../../util/urlHelpers';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import '../../../styles/phoneNumberInput.css'

import css from './SignupForm.module.css';
import { checkIfNumberExist, sendOtp, verifyOtp } from '../../../util/api';
import IconCard from '../../../components/SavedCardDetails/IconCard/IconCard';

export const ACCOUNT_TYPE = {
  CLIENT: 'individual',
  BUSINESS: 'business',
};

const SignupFormComponent = props => {
  const { onSubmit, onManageDisableScrolling, ...rest } = props;

  const location = useLocation();

  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.CLIENT);
  const [invalidPhoneValue, setInvalidPhoneValue] = useState();
  const [isVerifying, setIsVerifying] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const handleSubmitWithAccountType = useCallback(
    values => {
      onSubmit({ ...values, accountType });
    },
    [onSubmit, accountType]
  );

  useEffect(() => {
    const isType = location.state?.from?.includes('?type');

    if (!isType) return;

    const parsed = parse(location.state.from);
    const type = Object.values(parsed)[0];

    if (type === ACCOUNT_TYPE.CLIENT || type === ACCOUNT_TYPE.BUSINESS) {
      setAccountType(type);
    }
  }, [location.state?.from]);

  return (
    <FinalForm
      {...rest}
      onSubmit={handleSubmitWithAccountType}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          form,
          values,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // Phone number 
        const phoneNumberPlaceholder = intl.formatMessage({
        id: 'SignupForm.phoneNumberPlaceholder',
        });

        const {phoneNumber, isVerified = false} = values || {}
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !phoneNumber || !isVerified;

        const changePhoneValue = () => {
          if (phoneNumber) {
            const formattedNumber = formatPhoneNumberIntl(phoneNumber)
            if (isValidPhoneNumber(formattedNumber)) {
              setInvalidPhoneValue(false)
            } else {
              setInvalidPhoneValue('Invalid phone number')
            }
          }
        }

        const handleVerifyNumber = async () => {
          try{
            setIsVerifying(true)
            const {phoneNumberExists} = await checkIfNumberExist({phoneNumber});
  
            if(phoneNumberExists){
              throw new Error('Phone number exist! Please try different number.')
            }
            
            const response = await sendOtp({phoneNumber});
            if(response.status === 'pending'){
              setIsVerifying(false)
              setOpenOtpModal(true)
            }   
            else throw new Error('Failed!')         
          }
          catch (error) {
            setIsVerifying(false)
            setInvalidPhoneValue(`${error}`)
          }
        }

        const handleChangeInput = (e) => {
          const regex = /^\d{0,6}$/;
          const value = e.target.value;
          
          if(regex.test(value)){
            form.change('otp', value);
          }
        }

        const handleVerifyOtp = async () => {
          try{
            setIsLoading(true)
            // const response = await verifyOtp({phoneNumber, otp: values?.otp})

            // if(response.status === 'approved'){
              setIsLoading(false)
              setOpenOtpModal(false)
              form.change('isVerified', 'verified')
            // }   
            // else throw new Error('Failed!')  
          }
          catch(error){
            setIsLoading(false)
            setInvalidPhoneValue(`${error}`)
          }
        }

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.accountTypeWrapper}>
              <PrimaryButton
                type="button"
                className={classNames(css.accountType, {
                  [css.selected]: accountType === ACCOUNT_TYPE.CLIENT,
                  [css.selectedLeft]: accountType === ACCOUNT_TYPE.CLIENT,
                })}
                onClick={() => setAccountType(ACCOUNT_TYPE.CLIENT)}
              >
                <FormattedMessage id="SignupForm.accountTypeClient" />
              </PrimaryButton>
              <PrimaryButton
                type="button"
                className={classNames(css.accountType, {
                  [css.selected]: accountType === ACCOUNT_TYPE.BUSINESS,
                  [css.selectedRight]: accountType === ACCOUNT_TYPE.BUSINESS,
                })}
                onClick={() => setAccountType(ACCOUNT_TYPE.BUSINESS)}
              >
                <FormattedMessage id="SignupForm.accountTypeBusiness" />
              </PrimaryButton>
            </div>

            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              {accountType === ACCOUNT_TYPE.BUSINESS && (
                <FieldTextInput
                  type="text"
                  className={css.businessName}
                  id={formId ? `${formId}.bname` : 'bname'}
                  name="bname"
                  label={intl.formatMessage({
                    id: 'SignupForm.businessNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.businessNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.businessNameRequired',
                    })
                  )}
                />
              )}
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
              <div className={css.phoneNumberInput}>
                <label>Phone number</label>
                <PhoneInput
                  className={css.fieldTextInput}
                  placeholder={phoneNumberPlaceholder}
                  value={formatPhoneNumberIntl(phoneNumber)}
                  onChange={value => {
                    form.change('phoneNumber', value)
                    setInvalidPhoneValue(false)
                  }}
                  error={phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                  onBlur={changePhoneValue}
                  defaultCountry='GB'
                />
                {isVerified ? (
                  <div className={css.verifyText}>
                    Verified
                    <IconCard brand='check' />
                  </div>
                ) : (
                  <>
                    {(phoneNumber && !invalidPhoneValue) && (
                      <div className={css.verifyText} onClick={handleVerifyNumber}>
                        {isVerifying ? <IconSpinner /> : 'Verify'}
                      </div>
                    )}
                  </>
                )}
                {invalidPhoneValue &&
                  <p className={css.invalidPhoneValue}>
                    {invalidPhoneValue}
                  </p>
                }
              </div>
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={openOtpModal}
              onClose={() => setOpenOtpModal(false)}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.optModal}>
                <FieldTextInput
                  type="text"
                  className={css.otp}
                  id={formId ? `${formId}.otp` : 'otp'}
                  name="otp"
                  label='Enter OTP'
                  onChange={handleChangeInput}
                />
                <PrimaryButton 
                  type="button" 
                  onClick={handleVerifyOtp} 
                  disabled={values?.otp?.length !== 6}
                  inProgress={isLoading}
                >
                  Submit
                </PrimaryButton>
                {invalidPhoneValue &&
                  <p className={css.invalidPhoneValue}>
                    {invalidPhoneValue}
                  </p>
                }
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
