import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconInquiry, NamedLink } from '..';

import css from './ModalMissingInformation.module.css';

const CompleteProfileReminder = props => {
  const { className, onClick } = props;

  return (
    <div className={className}>
      <IconInquiry className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.completeProfileTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.completeProfileText" />
      </p>

      <div className={css.bottomWrapper} onClick={onClick}>
        <NamedLink className={css.reminderModalLinkButton} name="ProfileSettingsPage">
          <FormattedMessage id="ModalMissingInformation.completeProfile" />
        </NamedLink>
      </div>
    </div>
  );
};

export default CompleteProfileReminder;
