import React, { useCallback, useEffect, useMemo } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { updateReminders } from '../../ducks/ui.duck';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { useIntl } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { pathByRouteName } from '../../util/routes';

import { Modal } from '../../components';

import EmailReminder from './EmailReminder';
import CompleteProfileReminder from './CompleteProfileReminder';
import { ACCOUNT_TYPE } from '../../containers/AuthenticationPage/SignupForm/SignupForm';
import { getCookie, setCookie } from '../../util/cookie';

import css from './ModalMissingInformation.module.css';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'PasswordResetPage',
  'StripePayoutPage',
];

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const COMPLETE_PROFILE = 'COMPLETE_PROFILE';

const DELAY_BETWEEN_REMINDERS = 10000; // 10s

const ModalMissingInformation = props => {
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    location,
    routeConfiguration,
    rootClassName,
    className,
    containerClassName,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onManageDisableScrolling,
    onResendVerificationEmail,
  } = props;

  const intl = useIntl();

  const { reminders, seenReminders, currentReminderInd } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  const whitelistedPaths = useMemo(
    () =>
      MISSING_INFORMATION_MODAL_WHITELIST.map(page => pathByRouteName(page, routeConfiguration)),
    [routeConfiguration]
  );
  const isPageWhitelisted = useMemo(() => whitelistedPaths.includes(location.pathname), [
    whitelistedPaths,
    location,
  ]);

  const user = useMemo(() => ensureCurrentUser(currentUser), [currentUser]);
  const currentUserLoaded = useMemo(() => user && user.id?.uuid, [user]);

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    rootClassName,
    className,
  ]);

  const onUpdateReminders = useCallback(
    (reminders, seenReminders, currentReminderInd) => {
      const payload = {
        ...(reminders !== undefined ? { reminders } : {}),
        ...(seenReminders !== undefined ? { seenReminders } : {}),
        ...(currentReminderInd !== undefined ? { currentReminderInd } : {}),
      };
      dispatch(updateReminders(payload));
    },
    [dispatch]
  );

  const closeReminder = useCallback(() => {
    const newSeenReminders = [...new Set([...seenReminders, reminders[currentReminderInd]])];

    onUpdateReminders(undefined, newSeenReminders, null);

    const notSeenReminder = reminders.find(r1 => !newSeenReminders.some(r2 => r1 === r2));

    if (notSeenReminder) {
      const newReminderInd = reminders.findIndex(reminder => reminder === notSeenReminder);

      if (newReminderInd !== -1) {
        setTimeout(
          () => onUpdateReminders(undefined, undefined, newReminderInd),
          DELAY_BETWEEN_REMINDERS
        );
      }
    }
  }, [currentReminderInd, reminders, seenReminders]);

  const content = useMemo(() => {
    if (!currentUserLoaded) {
      onUpdateReminders(undefined, undefined, null);
      return;
    }
    if (currentReminderInd === null || currentReminderInd >= reminders.length) {
      return;
    }

    switch (reminders[currentReminderInd]) {
      case EMAIL_VERIFICATION:
        return (
          <EmailReminder
            className={classes}
            user={user}
            onResendVerificationEmail={onResendVerificationEmail}
            sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            sendVerificationEmailError={sendVerificationEmailError}
          />
        );
      case COMPLETE_PROFILE:
        return <CompleteProfileReminder className={classes} user={user} onClick={closeReminder} />;
      default:
        return null;
    }
  }, [currentUserLoaded, currentReminderInd]);

  // Setup the reminders
  useEffect(() => {
    // If page is whitelisted, do not show any reminders
    if (isPageWhitelisted) {
      return;
    }

    // Emails are sent when order is initiated
    // Customer is likely to get email soon when she books something
    // Provider email should work - she should get an email when someone books a listing
    const hasOrders = currentUserHasOrders === true;
    const hasListingsOrOrders = currentUserHasListings || hasOrders;

    const emailUnverified = !user?.attributes?.emailVerified;
    const emailVerificationNeeded = hasListingsOrOrders && emailUnverified;

    if (emailVerificationNeeded) {
      const hasEmailReminder = reminders.find(reminder => reminder === EMAIL_VERIFICATION);

      if (!hasEmailReminder) {
        onUpdateReminders([...new Set([...reminders, EMAIL_VERIFICATION])], undefined, 0);
      }
    }

    const profileCompleted = !!user.profileImage;
    const isBusiness =
      user?.attributes?.profile?.protectedData?.accountType === ACCOUNT_TYPE.BUSINESS;
    const profileCompleteNeeded = isBusiness && !profileCompleted;

    if (profileCompleteNeeded) {
      const completeProfileReminderShown = getCookie('completeProfileReminderShown');

      const hasProfileCompleteReminder = reminders.find(reminder => reminder === COMPLETE_PROFILE);
      const shouldCompleteProfileReminder =
        !completeProfileReminderShown && !hasProfileCompleteReminder;

      if (shouldCompleteProfileReminder) {
        onUpdateReminders([...new Set([...reminders, COMPLETE_PROFILE])], undefined, 0);

        setCookie('completeProfileReminderShown', 1, 2);
      }
    }
  }, [location, user, currentUserHasListings, currentUserHasOrders]);

  return (
    <Modal
      id="MissingInformationReminder"
      containerClassName={containerClassName}
      isOpen={!isPageWhitelisted && currentReminderInd !== undefined && currentReminderInd !== null}
      onClose={closeReminder}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={intl.formatMessage({
        id: 'ModalMissingInformation.closeVerifyEmailReminder',
      })}
    >
      {content}
    </Modal>
  );
};

ModalMissingInformation.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalMissingInformation.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedModalMissingInformation = props => {
  const routeConfiguration = useRouteConfiguration();

  return <ModalMissingInformation routeConfiguration={routeConfiguration} {...props} />;
};

export default EnhancedModalMissingInformation;
