import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDuration.module.css';

const IconDuration = props => {
  const { className, rootClassName, isOn = true } = props;
  const classes = classNames(rootClassName || css.root, className, { [css.disabled]: !isOn });

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="m10 24h-10v-2h10zm-2-6h-8v2h8zm-2-4h-6v2h6zm6-14a12.013 12.013 0 0 0 -12 12h2a10 10 0 1 1 10 10v2a12 12 0 0 0 0-24zm-1 7v5.414l3.293 3.293 1.414-1.414-2.707-2.707v-4.586z" />
    </svg>
  );
};

IconDuration.defaultProps = { className: null };

IconDuration.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDuration;
